
$blanc: #fff;
$bleu: #b5cbe0;
$bleu-clair: #2daae1;
$bleu-fonce: #1d60a1;
$bleu-pale: #f1f5f9;
$bleu-sombre: #0f3254;
$dore: #d0b70f;
$gris: #939591;
$gris-acces-rapide: #c4cac6;
$gris-clair: #f2f2f2;
$gris-clair-acces-rapide: #e0e2df;
$gris-fonce: #666;
$gris-fonce-acces-rapide: #949591;
$gris-pale: #ccc;
$noir: #1e1e1e;
$noir-pale: #333;
$orange: #ed7d23;
$rose-clair: #f55c93;
$rose: #b13770;
$rouge: #c3293a;
$vert: #76b82a;
$vert-fonce-acces-rapide:#58881f;
$vert-fonce: #609822;
$vert-programme: #73a935;
